import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { User } from '@core/entities/user';
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout } from '../../../../../core/auth';

@Component({
  selector: 'kt-user-profile',
  styles: [
    '.kt-header__topbar-user { display: flex; flex-direction: column; align-items: center; padding: 10px; }'
  ],
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  user$?: Observable<User | undefined>;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() icon?: boolean;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
