<!-- splash screen -->
<div #splashScreen class="kt-splash-screen">
  <span *ngIf="loaderType === 'spinner-message'">{{ loaderMessage }}</span>
  <img
    *ngIf="loaderType === 'spinner-logo'"
    src="./assets/media/company-logos/logo-brand-rvpfactory-v2.png"
    alt="Logo"
  />
  <mat-spinner diameter="40"></mat-spinner>
</div>
