<!-- begin::Scrolltop -->
<div
  ktScrollTop
  [options]="scrollTopOptions"
  id="kt_scrolltop"
  class="kt-scrolltop"
>
  <i class="la la-arrow-up"></i>
</div>
<!-- end::Scrolltop -->
