<div
  ngbDropdown
  placement="bottom-right"
  *ngIf="user$ | async as _user"
  class="kt-header__topbar-item kt-header__topbar-item--user"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <div
      class="kt-header__topbar-user"
      [ngClass]="{ 'kt-header__topbar-icon': icon }"
    >
      <span [style.color]="'white'" class="kt-header__topbar-username">{{ _user.name }}</span>
      <kt-generic-button appearance="stroked" color="white" (click)="logout()">Sign Out</kt-generic-button>
    </div>
  </div>
</div>
