// Angular
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutConfigService, SplashScreenService } from '@corebase/layout';
import { ObjectUtil } from '@coreutils';

@Component({
  selector: 'kt-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  loaderLogo!: string;
  loaderType!: string;
  loaderMessage!: string;

  @ViewChild('splashScreen', { static: true }) splashScreen!: ElementRef;

  constructor(
    private el: ElementRef,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService) {
  }

  ngOnInit() {
    // init splash screen, see loader option in layout.config.ts
    const loaderConfig = this.layoutConfigService.getConfig('loader');
    this.loaderLogo = ObjectUtil.getValueByPath(loaderConfig, 'logo');
    this.loaderType = ObjectUtil.getValueByPath(loaderConfig, 'type');
    this.loaderMessage = ObjectUtil.getValueByPath(loaderConfig, 'message');

    this.splashScreenService.init(this.splashScreen);
  }
}
